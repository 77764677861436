
@import url('antd/dist/antd.less');

.html, body {
  margin: 0;
  padding: 0;
}

body{
  background: #f5f5f5;
  min-height: 100vh;
}

* {box-sizing: border-box;}

.abs{
  position: absolute;
}
.vw100{width: 100vw}
.vh100{height: 100vh}
.flex{display: flex;}
.flex1{flex: 1}
.flex-none{flex: none}
.justify-end{justify-content: flex-end}
.items-start{align-items: flex-start}

.column{flex-direction: column;}
.align-center{align-items: center;}
.justify-center{justify-content: center;}
.space-between{justify-content: space-between;}

.f0{font-size: 0}
.f10{font-size: 10px;}
.f12{font-size: 12px;}
.f14{font-size: 14px;}
.f16{font-size: 16px;}
.f18{font-size: 18px;}
.f20{font-size: 20px;}

.mt8{margin-top: 8px}
.mt16{margin-top: 16px}
.mt24{margin-top: 24px}

.mb8{margin-bottom: 8px}
.mb16{margin-bottom: 16px}

.mr8{margin-right: 8px;}
.mr16{margin-right: 16px;}
.mr24{margin-right: 24px;}

.ml8{margin-left: 8px;}
.ml16{margin-left: 16px;}
.ml24{margin-left: 24px;}

.mx8{margin-left: 8px;margin-right: 8px}

.pb8{padding-bottom: 8px;}
.pb16{padding-bottom: 16px;}
.pb24{padding-bottom: 24px;}

.pt8{padding-top: 8px;}
.pt16{padding-top: 16px;}
.pt24{padding-top: 24px;}

.pl8{padding-left: 8px;}
.pl16{padding-left: 16px;}
.pl24{padding-left: 24px;}

.pr8{padding-right: 8px;}
.pr16{padding-right: 16px;}
.pr24{padding-right: 24px;}

.px24{padding-left: 24px;padding-right: 24px;}
.px16{padding-left: 16px;padding-right: 16px;}
.px8{padding-left: 8px;padding-right: 8px;}

.py8{padding-top: 8px;padding-bottom: 8px;}
.py16{padding-top: 16px;padding-bottom: 16px;}
.py24{padding-top: 24px;padding-bottom: 24px;}

.p8{padding: 8px;}
.p16{padding: 16px}
.p24{padding: 24px}


.bg-white{background: white !important;}
.bg-white1{background: #fafafa}

.grey1{color: #fafafa;}
.grey2{color: #f5f5f5;}
.grey3{color: #f0f0f0;}
.grey4{color: #d9d9d9;}
.grey5{color: #bfbfbf;}
.grey6{color: #8c8c8c;}
.grey7{color: #595959;}
.grey8{color: #434343;}
.grey9{color: #262626;}
.grey10{color: #1f1f1f;}
.grey11{color: #141414;}

.white{color: white;}

.green6{color: #52c41a !important;}
.orange6{color: #fa8c16 !important;}
.red6{color: #f5222d !important;}

.border{border: 1px solid #eee;}
.border-b{border-bottom: 1px solid #eee;}
.border-t{border-top: 1px solid #eee;}
.border-l{border-left: 1px solid #eee;}
.border-r{border-right: 1px solid #eee;}

.radius5{border-radius: 5px}
.radius10{border-radius: 10px}
.bold{font-weight: bold}
.block{display: block}
.tx-c{text-align: center}
.tx-l{text-align: left}
.tx-r{text-align: left}
.shadow{
  box-shadow: 0 0 3px #cccccc;
}

.shadow-box{
  box-shadow: 0 2px 8px #f0f1f2;
}


// antd
.child-pro-card-body-no-pad .ant-pro-card-body{
  padding: 0;
}


.debug *{
  background-color: rgba(255,0,0,0.1);
}

.cursor-pointer {
  cursor: pointer;
}

.quill-content img {
  width: 100%;
}

.money {
  font-size: 2rem;
}

.dataBox{
  height: 180px;
}


.overview{
  .iconWrapper {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #1890ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .activateUserIcon{
    font-size: 30px;
    color: white;
  }
  
  .title {
    font-size: 1.2rem;
  }
  
  .subtitle {
    font-size: 1rem;
    color: #999292ee;
  }
}

.c-upload-noList {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  margin: 20px 0;
}

.c-upload-imageList {
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  margin: 20px 0;
  .imageItem {
    display: flex;
    flex-wrap: wrap;
    width: 128px;
    height: 128px;
    margin: 0 8px 8px 0;
    padding: 8px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;

    .imageView {
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      .action {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}


.c-braft-uploadBtn {
  margin-top: 10px;
}
.c-braft-component {
  border: 1px solid #ccc;
}
.c-braft-msg {
  padding-left: 10px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
}

.order-refund .ant-carousel {
  width: 85%;
}

.ant-pro-card .ant-pro-card-title {
  display: flex;
  align-items: center;
}

.w-full.ant-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker {
  width: 100%;
}

.ant-pro-form-list-container {
  width: 100%;
}

.upload-w-full .ant-upload-list-picture-card-container {
  width: 100%;
  height: 300px;
}

.upload-w-full-picture-card .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 300px;
}

.ant-image-preview-img-wrapper {
  display: flex;
    justify-content: center;
    align-items: center;
}
.ant-image-preview-img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}


.ant-pro-steps-form-container {
  width: auto;
}

.full .ant-upload.ant-upload-select-picture-card, .full .ant-upload-list-picture-card-container {
  width: 100%;
}

.bf-content {
  min-height: 400px;
  height: auto !important;
}

.gap {
  gap: 0 2px;
  margin: 4px 0;
}

.rounded {
  border-radius: 5px;
}